import isMobilePhone from "validator/es/lib/isMobilePhone";
import isMongoId from "validator/es/lib/isMongoId";

export const idSchema = z.string().refine((val) => isMongoId(val));

export const phoneSchema = z.string().refine((val) => isMobilePhone(val));

export const nameSchema = z.string().min(3).max(30);

export const imageUrlSchema = z.string();
// Assuming we have these shared schemas. If not, we can define them here:

export const emailSchema = z.string().email().optional();
export const addressSchema = z.string().min(1).max(200);
export const vatSchema = z.string().max(20).optional();
